import React, { useState, useEffect } from 'react';
import { Box, Button, Switch, SelectChangeEvent, FormGroup, FormControlLabel, FormControl, Typography } from '@mui/material/'
import Layout from '../LayoutComponent';
import Edit from './CustomizationComponent';
import { dataClient, ComponentParams } from '../../APIClient/DataClient';
import SnackbarComponent from '../DisplayComponent';
import PieChart from './PieChartComponent'
import BarGraph from './BarGraphComponent'
import LineGraph from './LineGraphComponent'
interface SelectedComponent {
    id: string;
    type: string;
    data: any;
}

const Overview: React.FC = () => {
    const [selectedComponents, setSelectedComponents] = useState<SelectedComponent[]>([]);
    const [userEmail, setUserEmail] = useState<string>('');
    const [createComponentFailed, setCreateComponentFailed] = useState(false);
    const [createComponentSuccess, setCreateComponentSuccess] = useState(false);
    const [displayComponentFailed, setDisplayComponentFailed] = useState(false);
    const [displayComponentSuccess, setDisplayComponentSuccess] = useState(false);
    const [fetchComponentMetaDataFailed, setFetchComponentMetaDataFailed] = useState(false);
    const [deleteComponentFailed, setDeleteComponentFailed] = useState(false);
    const [deleteComponentSuccess, setDeleteComponentSuccess] = useState(false);
    const [showDeleteIcons, setShowDeleteIcons] = useState(false);

    useEffect(() => {
        // Retrieve user's email from local storage
        const email = localStorage.getItem('userEmail');
        if (email) {
            setUserEmail(email);
            // Fetch components metadata tagged to the user's email
            fetchComponentMetaData(email);
        }
    }, []);

    const fetchComponentMetaData = async (email: string) => {
        // Fetch components metadata tagged to the user's email
        const response = await dataClient.fetchComponentMetaDataByEmail(email);
        if (response.status === 'success') {
            const componentsMetaData = response.data || [];
            // Display components corresponding to the fetched components metadata
            displayComponents(componentsMetaData);
        } else {
            setFetchComponentMetaDataFailed(true);
        }
    };

    const displayComponents = async (componentsMetaData: string[]
    ) => {
        // Iterate over each element in componentsMetaData
        for (const metaData of componentsMetaData) {
            // Call fetchComponentData for each element
            const componentData = await dataClient.fetchComponentData(metaData);
            if (componentData.status === 'success') {
                setDisplayComponentSuccess(true);
                const aggregatedData = componentData?.aggregatedData || [];
                const componentId = componentData?.componentId;
                const chartType = componentData?.chartType || "";

                setSelectedComponents(prevComponents => {
                    const updatedComponents = [...prevComponents];
                    if (componentId) {
                        updatedComponents.push({ id: componentId, type: chartType, data: aggregatedData });  
                    }
                    return updatedComponents;
                });

            } else {
                setDisplayComponentFailed(true);
            }
        }
    };

    const handleComponentChange = async (
        event: SelectChangeEvent<string>,
        parameters: {
            [key: string]: string[] | string | null; 
        }
    ) => {
        const selectedType = event.target.value;
        const componentId = selectedType + "_" + Math.random().toString(36).substring(7); // Generate unique componentId
        console.log("params: ", parameters);
        // Create Component using dataClient
        const response = await dataClient.createComponent(parameters, componentId, userEmail); // Include componentId and userEmail
        if (response.status === 'success') {
            window.location.reload();
            setCreateComponentSuccess(true);
        } else {
            setCreateComponentFailed(true);
        } 
    };

    // Function to handle deletion of a component
    const handleDelete = async (componentId: string) => {
        // Implement logic to delete the component with the specified componentId
        const response = await dataClient.deleteComponent(componentId);
        if (response.status === 'success') {
            window.location.reload();
            setDeleteComponentSuccess(true);
        } else {
            setDeleteComponentFailed(true);
        }
    };

    const handleDisplayDeleteIcon = () => {
        setShowDeleteIcons(prevState => !prevState);
    };

    return (
        <Layout>
            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
                    {/* Edit Component */}
                    <Edit onComponentChange={handleComponentChange} />

                    {/* Delete Toggle Switch */}
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="start"
                                control={<Switch
                                    checked={showDeleteIcons}
                                    onChange={handleDisplayDeleteIcon}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="error"
                                />}
                                label={
                                    <Box boxShadow={3} borderRadius={4}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#f44336', color: 'white' }}
                                            onClick={handleDisplayDeleteIcon}
                                        >
                                            DELETE
                                        </Button>
                                    </Box>
                                }
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
                <div style={{ justifyContent: 'center' }}>
                    {/* Content of the inner div */}
                </div>
            </div>
            <Box sx={{ display: 'flex', height: '100%' }}>
                {/* Main Content */}
                <Box sx={{ flexGrow: 1 }}>
                    {/* Displaying of components */}
                    <Box
                        sx={{
                            p: 1,
                            backgroundColor: '#f0f0f0',
                            display: 'flex',
                            flexWrap: 'wrap', // Allow components to wrap to the next line
                            gap: '27px', // Adjust gap between components as needed
                            overflow: 'auto',
                        }}
                    >
                        {selectedComponents.map((component) => (
                            <div key={component.id} style={{ position: 'relative' }}>
                                {showDeleteIcons && (
                                    <Button
                                        style={{ position: 'absolute', bottom: '4px', right: '4px' }}
                                        color="error"
                                        onClick={() => handleDelete(component.id)}
                                    >
                                        Delete
                                    </Button>
                                )}
                                {component.type === 'Pie Chart' && (
                                    <PieChart
                                        key={component.id}
                                        data={component.data}
                                        componentId={component.id}
                                        showDeleteIcon={showDeleteIcons} 
                                        handleDelete={handleDelete} 
                                    />
                                )}
                                {component.type === 'Bar Graph' && (
                                    <BarGraph
                                        key={component.id}
                                        data={component.data}
                                        componentId={component.id}
                                        showDeleteIcon={showDeleteIcons}
                                        handleDelete={handleDelete}
                                    />
                                )}
                                {component.type === 'Line Graph' && (
                                    <LineGraph
                                        key={component.id}
                                        data={component.data}
                                        componentId={component.id}
                                        showDeleteIcon={showDeleteIcons}
                                        handleDelete={handleDelete}
                                    />
                                )}
                                {/* Add cases for other component types */}
                            </div>
                        ))}
                    </Box>
                </Box>
            </Box>
            {/* Snackbar to display creation component failure */}
            <SnackbarComponent
                message="Creation Fail"
                open={createComponentFailed}
                onClose={() => setCreateComponentFailed(false)}
                severity="error"
            />
            {/* Snackbar to display creation component success */}
            <SnackbarComponent
                message="Creation Success"
                open={createComponentSuccess}
                onClose={() => setCreateComponentSuccess(false)}
                severity="success"
            />
            {/* Snackbar to display display component failure */}
            <SnackbarComponent
                message="Display Fail"
                open={displayComponentFailed}
                onClose={() => setDisplayComponentFailed(false)}
                severity="error"
            />
            {/* Snackbar to display display component success */}
            <SnackbarComponent
                message="Display Success"
                open={displayComponentSuccess}
                onClose={() => setDisplayComponentSuccess(false)}
                severity="success"
            />
            {/* Snackbar to display fetch component metadata failure */}
            <SnackbarComponent
                message="Fetch Fail"
                open={fetchComponentMetaDataFailed}
                onClose={() => setFetchComponentMetaDataFailed(false)}
                severity="error"
            />
            {/* Snackbar to display delete component failure */}
            <SnackbarComponent
                message="Delete Fail"
                open={deleteComponentFailed}
                onClose={() => setDeleteComponentFailed(false)}
                severity="error"
            />
            {/* Snackbar to display delete component success */}
            <SnackbarComponent
                message="Delete Success"
                open={deleteComponentSuccess}
                onClose={() => setDeleteComponentSuccess(false)}
                severity="success"
            />
        </Layout>
    );
}

export default Overview;
