import { Alert } from '@mui/material';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

// Check if there is a session key in cookie
export function checkSessionKey() {
    const cookies = document.cookie
    return cookies.includes('session_key') // HQ: should check the exact session_key
}

interface RouteProps {
    children: ReactNode
}

// Route wrapper for authentication routes, redirects authenticated users to the homepage
export const AuthRoute: React.FC<RouteProps> = ({ children }) => {
    console.log('isAuthenticated?:', checkSessionKey())
    const isAuthenticated = checkSessionKey()
    return isAuthenticated ? <Navigate to="/overview" replace /> : children
};

// Route wrapper for private routes, redirects unauthenticated users to the sign-in page
export const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
    const isAuthenticated = checkSessionKey()
    return isAuthenticated ? children : <Navigate to="/auth/signin" replace />
};