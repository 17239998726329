import React from 'react';
import Layout from '../../DashboardUI/LayoutComponent';

const Forecast: React.FC = () => {
    return (
        <Layout>
            <div style={{ textAlign: 'center', margin:'auto' }}>
                <h1>Forecast Analytics Page</h1>
            </div>
        </Layout>
    );
}

export default Forecast;
