import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ParameterData {
    [parameter: string]: number | null;
}

interface DateData {
    [date: string]: ParameterData[];
}

interface AggregateData {
    [pond: string]: DateData;
}

interface LineGraphProps {
    data: AggregateData;
    componentId: string;
    showDeleteIcon: boolean;
    handleDelete: (componentId: string) => void;
}

const LineGraph = ({ data, componentId, showDeleteIcon, handleDelete }: LineGraphProps) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    console.log("data: ", data);
    let title = '';

    if (componentId.includes('Biology')) {
        title = 'Pathogen Line Graph';
    }
    if (componentId.includes('Chemical')) {
        title = 'Chemical Line Graph';
    }
    if (componentId.includes('Physical')) {
        title = 'Physical Line Graph';
    }

    useEffect(() => {
        if (chartContainerRef.current) {
            const myChart = echarts.init(chartContainerRef.current);

            const pondName = Object.keys(data)[0]; // Assuming only one pond
            const dates = Object.keys(data[pondName]);
            const legendData: string[] = [];

            // Collect all unique parameters from the dataset
            dates.forEach(date => {
                const dateData = data[pondName][date][0]; // Assuming single entry per date
                Object.keys(dateData).forEach(parameter => {
                    if (!legendData.includes(parameter)) {
                        legendData.push(parameter);
                    }
                });
            });

            const seriesData = legendData.map(parameter => ({
                name: parameter,
                type: 'line',
                data: dates.map(date => data[pondName][date][0][parameter] !== null ? data[pondName][date][0][parameter] : '-'), // If value is null, use '-' as placeholder
                areaStyle: {}
            }));

            const option = {
                title: {
                    text: `${title} (${pondName})`,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    bottom: -10,
                    left: 'center',
                    data: legendData
                },
                xAxis: {
                    type: 'category',
                    data: dates,
                    boundaryGap: false
                },
                yAxis: {
                    type: 'value'
                },
                series: seriesData
            };

            myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        }
    }, [data]);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                bgcolor: 'white',
                p: 2,
                width: '1050px',
                height: '520px',
                aspectRatio: '1', // Maintain a square aspect ratio
            }}
        >
            <div ref={chartContainerRef} style={{ height: 'calc(100% - 40px)', width: '100%' }}></div>
            {showDeleteIcon && (
                <IconButton
                    style={{
                        position: 'absolute',
                        bottom: '4px',
                        right: '4px',
                        color: 'red',
                    }}
                    onClick={() => handleDelete(componentId)}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default LineGraph;
