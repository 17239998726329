import Api from './Api';

export interface ComponentParams {
    // Define any parameters needed for creating component
}

interface AggregatedData {
    [group: string]: {
        [biologicalName: string]: number;
    };
}

class DataClient {
    async createComponent(params: ComponentParams, componentId: string, userEmail: string): Promise<{ status?: string }> {
        try {
            const response = await Api.request('/api/create-component/', {
                method: 'POST',
                data: { params, componentId, userEmail },
            });
            return response;
        } catch (error) {
            console.error('Error creating components:', error);
            return { status: 'fail' };
        }
    }

    async fetchComponentMetaDataByEmail(userEmail: string): Promise<{ status?: string; data?: string[]}> {
        try {
            const response = await Api.request('/api/fetch-components-metadata/', {
                method: 'POST',
                data: { userEmail },
            });
            return response;
        } catch (error) {
            console.error('Error fetching component metadata:', error);
            return { status: 'fail' };
        } 
    }

    async fetchComponentData(metaData: string): Promise<{ status?: string; componentId?: string; chartType?: string; aggregatedData?: AggregatedData  }> {
        try {
            const response = await Api.request(`/api/fetch-components-data/`, {
                method: 'POST',
                data: { metaData },
            });
            return response;
        } catch (error) {
            console.error('Error fetching component data:', error);
            return { status: 'fail' };
        }
    }

    async deleteComponent(componentId: string): Promise<{ status?: string; error?: string }> {
        try {
            const response = await Api.request(`/api/delete-component/${componentId}`, {
                method: 'DELETE',
            });
            return response;
        } catch (error) {
            console.error('Error deleting component:', error);
            return { status: 'fail' };
        }
    }
}

export const dataClient = new DataClient();
