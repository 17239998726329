// Sign In component

import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { authClient } from '../APIClient/AuthClient';
import { useState } from 'react';
import SnackbarComponent from '../DashboardUI/DisplayComponent';

export default function SignInComponent() {
    const navigate = useNavigate(); // Initialize navigate
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signInFailed, setSignInFailed] = useState(false);
    const [signInSuccess, setSignInSuccess] = useState(false);

    // Event handlers to update state based on user input
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setEmail(event.target.value);
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value);

    // Function to handle login
    const handleLogin = () => {
        authClient
            .signIn({ email: email, password: password })
            .then((res) => {
                if (res.status === 'success') {
                    // Store email in local storage
                    localStorage.setItem('userEmail', email);

                    const urlParams = new URLSearchParams(location.search);
                    const next = urlParams.get('next') || '/';
                    setSignInSuccess(true);
                    setTimeout(() => {
                        navigate(next);
                    }, 2000); // 2000 milliseconds delay

                } else {
                    setSignInFailed(true);
                }
            });
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '& div': { display: 'flex' },
                '& .MuiTextField-root': { m: 1, flexGrow: 1 },
                '& Button': { m: 1 },
                '& p': { m: 1 },
                '& h2': { m: 1 },
            }}
        >
            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                Sign in for AquaCulture
            </Typography>
            <div>
                <TextField
                    required
                    fullWidth
                    id="email-input"
                    label="Email"
                    autoComplete="email"
                    onChange={handleEmailChange}
                >
                    {email}
                </TextField>
            </div>
            <div>
                <TextField
                    required
                    id="password-input"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={handlePasswordChange}
                >
                    {password}
                </TextField>
            </div>
            <Button variant="contained" onClick={handleLogin}>
                Submit
            </Button>
            <Typography color="text.secondary" variant="body2">
                Don't have an account?{' '}
                <Link href={'/auth/signup'} underline="hover" variant="subtitle2">
                    Sign up
                </Link>
            </Typography>

            {/* Snackbar to display sign-in failure */}
            <SnackbarComponent
                message="Sign in Fail"
                open={signInFailed}
                onClose={() => setSignInFailed(false)}
                severity="error"
            />
            {/* Snackbar to display sign-in success */}
            <SnackbarComponent
                message="Sign in Success"
                open={signInSuccess}
                onClose={() => setSignInSuccess(false)}
                severity="success"
            />
        </Box>
    );
}
